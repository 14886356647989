// 一覧画面 
import React from "react"
import "../styles/post-link.css"
import { Link } from "gatsby"

import ARTICLE_IMG from "../../static/post_link.png"


export default function PostLink({ post }) {
    const { id,title, createdAt, updatedAt} = post;
    const id_val = id.replace( /-/g, '_' );
    const pageLink = `/post/` + id_val + `/`;
    return (
        <div className="post-link">
            <div>
                <img src={ARTICLE_IMG} className="post-link-image" alt="post-cover"></img>
            </div>
            <div className="post-link-text">
                <Link to={pageLink} className="post-link-anchor">
                  <h2>{title}</h2><br/><span className="post-link-date">作成日時 : {createdAt} 　　 更新日時 : {updatedAt}</span>
                </Link>
            </div>
        </div>
    )
}


//                <p className="post-link-body">{body.internal.content}</p>
//<h2>{title}</h2><span className="post-link-date">{createdAt}</span>
//<img src={ARTICLE_IMG} className="post-link-image" alt="post-cover" width="280" height="188"></img>
