// /src/pages/blog_cloudflare.js
import React from "react"
import Layout from "../components/layout"
import PostLink from "../components/post-link"
import { graphql } from "gatsby"
import Seo from "../components/seo"

const base_strs = {
  description: 'cloudflareをエッジコンピューティングという視点で紹介した記事一覧::作成日時逆順 :',
  keywords: 'CloudFlare,Edge Computing,articles listｓ,作成日時逆順,'
};

export default function Home({ data }) {
  const edges = data.allContentfulTechBlog.edges;
  var titles_str = "";
  edges.map(edge =>
    titles_str = titles_str + edge.node.title + ","
  )
  const description_str = base_strs.description + titles_str;
  const keywords_str = base_strs.keywords + titles_str;
  return (
    <Layout>
      <Seo title="CloudFlare Edge Computing articles list" description={description_str} keywords={keywords_str}/>
      <p className="pad_top2">
      cloudflareをエッジコンピューティングという視点で紹介した記事一覧 作成日時逆順<br/>
      新規記事からご覧になりたい方向けです。
      </p>
      {data.allContentfulTechBlog.edges.map(edge =>
        <PostLink post={edge.node} />
      )}
    </Layout>
  )
}

export const query = graphql`
query CloudflareQuery {
  allContentfulTechBlog (filter: {tag: {eq: "cloudflare"}},sort: {fields: createdAt order: DESC}){
    edges {
      node {
        id
        title
        body {
          internal {
            content
          }
        }
        tag
        createdAt(locale: "ja-JP", formatString: "YYYY年MM月DD日")
        updatedAt(locale: "ja-JP", formatString: "YYYY年MM月DD日")
      }
    }
  }
}
`